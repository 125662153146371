<template>
  <div id="index" ref="index">
    <section class="flex flex-col items-center w-full min-h-screen">
      <img class="h-32 md:h-24" src="@/assets/img/logo.svg" alt="Good Way" />
      <div class="container py-10 leading-relaxed">
        <Find />
        <Table />
      </div>
    </section>
    <Copy />
  </div>
</template>

<script>
import Copy from "@/components/Theme/Footer";
import Find from "@/components/Blog/Find";
import Table from "@/components/Blog/SearchTable";

export default {
  name: "BlogSearch",
  components: {
    Copy,
    Find,
    Table,
  },
};
</script>
